import BackendService from './BackendService';

export default class RoomService extends BackendService {

    constructor() {
        super();
    }





    getRooms(status, property_id) {
        if(property_id){
            return this.httpCall('rooms',{
                params: {
                    status: status,
                    property: property_id
                },
            });
        }else{
            return this.httpCall('rooms',{
                params: {
                    status: status
                },
            });

        }
    }
    getRoomsList(params) {
        return this.httpCall('rooms',{
            params: params,
        });

    }
    getRoomId(room_id) {

        return this.httpCall('room/'+room_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    createRoom(room) {

        return this.httpCall('room',{
            method: 'POST',
            data: {
                name: room.es.name,
                property: room.property,
                description: room.es.description,
                cod: room.cod,
                type: room.type,
                area: room.area,
                view: room.view,
                no_rooms: room.no_rooms,
                no_baths: room.no_baths,
                max_pax: parseInt(room.max_pax),
                max_adults: parseInt(room.max_adults),
                max_children: parseInt(room.max_children),
                max_jr: parseInt(room.max_jr),
                amenities:room.amenities,
                agregate_values:room.aggregates,
                status: true,


            },
        });

    }
    updateRoom(room) {
        //console.log('user_info', user);

        return this.httpCall('room/'+room._id,{
            method: 'PUT',
            data: {

                //_id: room._id,
                name: room.es.name,
                property: room.property,
                description: room.es.description,
                cod: room.cod,
                type: room.type,
                area: room.area,
                view: room.view,
                no_rooms: room.no_rooms,
                no_baths: room.no_baths,
                max_pax: parseInt(room.max_pax),
                max_adults: parseInt(room.max_adults),
                max_children: parseInt(room.max_children),
                max_jr: parseInt(room.max_jr),
                amenities:room.amenities,
                agregate_values:room.aggregates,
            },
        });

    }
    updateRoomRatetiger(room) {
        //console.log('user_info', user);

        return this.httpCall('room/'+room._id,{
            method: 'PUT',
            data: {
                ratetiger_connect: room.ratetiger_connect,
                ratetiger_cod: room.ratetiger_cod,
            },
        });

    }
    updateStatus(id, status) {
        //console.log('user_info', user);

        return this.httpCall('room/'+id,{
            method: 'PUT',
            data: {
                status: status
            },
        });

    }



    deleteRoom(property_id) {

        return this.httpCall('room/'+property_id,{
            method: 'delete'
        });

    }
    createRoomConfig(config, room_id) {

        return this.httpCall('room/'+room_id+'/roomConfig',{
            method: 'POST',
            data: {
                single: config.single,
                double: config.double,
                king: config.king,
                queen: config.queen,
                couch: config.couch,
                bunk: config.bunk,
                availability: config.availability,


            },
        });

    }
    updateRoomConfig(config, room_id) {

        return this.httpCall('room/'+room_id+'/roomConfig/'+config._id,{
            method: 'PUT',
            data: {
                single: config.single,
                double: config.double,
                king: config.king,
                queen: config.queen,
                couch: config.couch,
                bunk: config.bunk,
                availability: config.availability,
            },
        });
    }
    deleteRoomConfig(config_id, room_id) {

        return this.httpCall('room/'+room_id+'/roomConfig/'+config_id,{
            method: 'delete'
        });

    }
    uploadSlider(room_id, data, action) {

        //console.log('fake same img:', room_id, data, action);

        return this.httpCall('room/'+room_id+'/slide', {
            method: action,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

    }
    deleteImage(room_id, image_id) {

        //console.log('delete img:', room_id, image_id);
        return this.httpCall('room/'+room_id+'/slide/'+image_id,{
            method: 'delete'
        });

    }


}
