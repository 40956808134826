<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('rate.section.inventory_available')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row">
            <!-- <div class="box-actions">
                <a  v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                   ><i class="fa fa-pencil"></i></a>
            </div>-->

            <div class="col-12">
                <card class="mt-2" v-loading="screenLoading"
                      element-loading-lock="true"
                      :element-loading-text="screenText"
                      element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory page-inventory">
                                <h4 class="is_title">Puede realizar cambios para un periodo máximo de 2 año apartir de la fecha de hoy.</h4>
                                <div class="form-dates w-border" v-show="info_page.index_page===1">
                                    <div class="box-dates  mt-3">
                                        <div class="row ">
                                            <div class="col-md-7">
                                                <label class="subtitle">Seleccionar fechas</label>
                                                <fg-input>
                                                    <el-date-picker v-model="inventory.date_range" type="daterange" placeholder="Inicio"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-placeholder="Fecha inicio"
                                                                    end-placeholder="Fecha fin"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="box-days">
                                                    <div class="form-check">
                                                        <label for="lunes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="lunes_d" v-model="inventory.week[1]">
                                                            <span class="form-check-sign" ></span>
                                                            Lun.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="martes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="martes_d" v-model="inventory.week[2]">
                                                            <span class="form-check-sign" ></span>
                                                            Mar.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="miercoles_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="miercoles_d" v-model="inventory.week[3]">
                                                            <span class="form-check-sign" ></span>
                                                            Mie.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="jueves_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="jueves_d" v-model="inventory.week[4]">
                                                            <span class="form-check-sign" ></span>
                                                            Jue.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="viernes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="viernes_d" v-model="inventory.week[5]">
                                                            <span class="form-check-sign" ></span>
                                                            Vie.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="sabado_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="sabado_d" v-model="inventory.week[6]">
                                                            <span class="form-check-sign" ></span>
                                                            Sab.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="domingo_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="domingo_d" v-model="inventory.week[0]">
                                                            <span class="form-check-sign" ></span>
                                                            Dom.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="box-button mb-5 mt-3">
                                                    <button type="button" class="add-item btn-default" @click="addRangeCalendar">Añadir al calendario</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="dates-manually">
                                        <div class="row">
                                            <label class="l-title">Ajustes manuales</label>

                                            <label class="item-year text-center color-blue-var year-header mb-3">{{calendar_info.yearHead}}</label>

                                            <div class="box-calendar" >


                                                <div class="item-month" v-for="(month_s, idsx) in calendar_info.monthsHead">
                                                    <div class="line-week lw-head" >
                                                        <span class="fa fa-chevron-left sp-icon" :class="(info_page.btn_back_status)?'':'n-visible'" v-if="idsx===0" @click="updDate('minus')"></span>
                                                        <label class="calendar-title wout-icon color-blue-var" :class="checkClass(idsx)" @click="addMonth(month_s)">{{nameMonth(month_s)}}</label>
                                                        <span class="fa fa-chevron-right sp-icon" v-if="idsx===2"  @click="updDate('plus')"></span>
                                                    </div>


                                                    <div class="line-week lw-head  color-blue-var" >
                                                        <div class="item-7" data-key="0">D</div>
                                                        <div class="item-7" data-key="1">L</div>
                                                        <div class="item-7" data-key="2">M</div>
                                                        <div class="item-7" data-key="3">M</div>
                                                        <div class="item-7" data-key="4">J</div>
                                                        <div class="item-7" data-key="5">V</div>
                                                        <div class="item-7" data-key="6">S</div>
                                                    </div>

                                                    <div class="line-week"  v-for="day_s in checkMonth(month_s)">
                                                        <div class="item-7" @click.capture="addCalendar(day_s[0])">  <span  :class="checkDay(day_s[0])">{{dayCalendar(day_s[0])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[1])">  <span  :class="checkDay(day_s[1])">{{dayCalendar(day_s[1])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[2])">  <span  :class="checkDay(day_s[2])">{{dayCalendar(day_s[2])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[3])">  <span  :class="checkDay(day_s[3])">{{dayCalendar(day_s[3])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[4])">  <span  :class="checkDay(day_s[4])">{{dayCalendar(day_s[4])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[5])">  <span  :class="checkDay(day_s[5])">{{dayCalendar(day_s[5])}}</span></div>
                                                        <div class="item-7" @click="addCalendar(day_s[6])">  <span  :class="checkDay(day_s[6])">{{dayCalendar(day_s[6])}}</span></div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-md-12">
                                                <div class="box-legends">
                                                    <label>
                                                        <span class="example-picker"></span>Fechas seleccionadas
                                                    </label>
                                                    <label class="right-t color-blue" @click="resetCalendar">
                                                        Restablecer
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="box-button mb-4">
                                                    <button type="button" class="add-item btn-default" :class="info_page.buttons.page_1?'':'inactive'" @click="orderDate">Siguiente</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>


                                <div class="form-preview w-border"  v-show="info_page.index_page===1">
                                    <label class="mt-3 l-title">Seleccionar actualizaciones</label>
                                </div>



                                <div class="form-preview w-border"  v-show="info_page.index_page===2 || info_page.index_page===3">
                                    <label class="l-title">Seleccionar fechas</label>
                                    <span class="brl text-normal text-capitalize">{{calendar_info.min}} - {{calendar_info.max}}</span>
                                    <span class="brl text-normal">Se actualizarán {{calendar_info.selected.length}} {{calendar_info.selected.length===1?'día':'días'}} </span>
                                    <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                    <a class="a-right type-text"  @click="info_page.index_page=1"> Editar</a>
                                </div>



                                <div class="form-updates w-border"  v-show="info_page.index_page===2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">

                                                <label class="subtitle">Ingresar actualizaciones</label>
                                                <label>
                                                    Los campos en blanco no se realizarán cambios.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="group-upd" v-for="(room, idx) in inventory.rooms">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label>
                                                            {{room.name}}
                                                        </label>
                                                    </div>


                                                    <div class="col-7">
                                                        <span>
                                                            Inventario y disponibilidad
                                                        </span>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>
                                                            Fecha límite reservación
                                                        </span>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-3">
                                                        <fg-input type="text"
                                                                  class="input-default"
                                                                  placeholder=""
                                                                  v-model="room.value"
                                                                  @input="checkChanges"
                                                        >
                                                        </fg-input>
                                                    </div>
                                                    <div class="col-4 pl-0">
                                                        <el-select class="select-default" size="large"  :name="$t('contract.form.link_type')"
                                                                   v-model="room.status"
                                                                   @change="checkChanges"
                                                        >
                                                            <el-option v-for="option in info_page.states" class="select-default" :value="option.value"
                                                                       :label="option.name" :key="option.value">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                    <div class="col-3">
                                                        <fg-input type="text"
                                                                  class="input-default"
                                                                  placeholder=""
                                                                  v-model="room.cutOff"
                                                                  @input="checkChanges"
                                                        >
                                                        </fg-input>
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4">
                                                <button type="button" class="add-item  btn-default"  :class="info_page.buttons.page_2?'':'inactive'" @click="goResume()">Vista previa</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>








                                <div class="form-preview w-border pb-3" v-show="info_page.index_page===3" >
                                    <label class="l-title">Actualizaciones</label>
                                    <div v-for="(room, idx) in inventory.rooms" >
                                        <div v-if="room.edit_status">
                                            <span class="brl text-normal">{{room.name}}</span>
                                            <p class="brl text-normal p-contain" v-show="room.value!==''"><span class="s-left">Inventario</span> <span class="s-right">{{room.value}}</span></p>
                                            <p class="brl text-normal p-contain"><span class="s-left">Disponibildiad</span> <span class="s-right">{{checkStatus(room.status)}}</span></p>
                                            <p class="brl text-normal p-contain" v-show="room.cutOff!==''"><span class="s-left"  >Fecha límite reservación</span> <span class="s-right">{{room.cutOff}}</span></p>
                                        </div>

                                    </div>

                                    <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                    <a class="a-right type-text"  @click="info_page.index_page=2"> Editar</a>
                                </div>




                                <div class="form-send"  v-show="info_page.index_page===3">
                                    <span class="fa fa-exclamation-circle s-icon"></span>
                                    <label>Verifica las actualizaciones, ya que son finales y no puedes regresar a un punto anterior.</label>
                                    <div class="box-button mb-4">
                                        <button type="button" class="add-item btn-success" @click="sendForm()">Enviar actualizaciones</button>
                                        <button type="button" class="add-item second btn-danger" @click="cancelAction()">Cancelar</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import {Breadcrumb, BreadcrumbItem} from 'src/components/index'
    import {Select, Option, DatePicker} from 'element-ui'
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import moment from 'moment';
    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';
    import RateService from '../../../js/services/RateService';


    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'

    const rateService = new RateService();
    const propertyService = new PropertyService();
    const roomService = new RoomService();
    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    export default {
        components: {
            [DatePicker.name]: DatePicker,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Option.name]: Option,
            [Select.name]: Select
        },
        computed: {
          ...mapGetters([ "selected_property","writting_permission"]),
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('rate.section.room_rates'),
                        path: '/admin/rates/rates-availability',
                        type: '',
                    },

                    {
                        name: this.$t('rate.section.inventory_available'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    property_id: {},
                    index_page: 1,
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    states:[
                        {
                            name: 'Abierta',
                            value: 0
                        },
                        {
                            name: 'Cerrada',
                            value: 1
                        },
                        {
                            name: 'Sin cambios',
                            value: 2
                        },
                    ],
                    room_model:{
                        name:'title',
                        value: '',
                        status: 2,
                    },
                    btn_back_status: false,
                    buttons:{
                        page_1: false,
                        page_2: false,
                        page_3: false,
                    }
                },


                calendar_info:{
                    monthsHead: [],
                    yearHead: '',
                    selected: [],
                    min: '',
                    max: ''
                },
                inventory: {
                    _id: '',
                    date_range: ['', ''],
                    end_date: '',
                    week:[1,1,1,1,1,1,1],
                    rooms:[

                    ],
                    formData: []

                },

                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate()-1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    },
                },

            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
            moment.locale('es');
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='FormInventory') {
                        //this.$refs.topProgress.start();
                        this.chargerItems(property_id);
                        this.info_page.index_page = 1;


                    }

                })
            },

            checkChanges(){
                let status_init = false;
                this.info_page.buttons.page_2 = false;
                let rooms = this.inventory.rooms;
                rooms.forEach(room=>{
                    room.edit_status = false;
                    if(room.value!==''){
                        this.info_page.buttons.page_2 = true;
                        room.edit_status = true;
                        //return true;
                    }
                    if(room.cutOff!==''){
                        this.info_page.buttons.page_2 = true;
                        room.edit_status = true;
                        //return true;
                    }
                    if(room.status<2){
                        this.info_page.buttons.page_2 = true;
                        room.edit_status = true;
                        //return true;
                    }
                });
            },

            chargerItems(property_id = null){
                this.initLoading();
                this.info_page.property_id = property_id;
                let date_ = moment().locale('es');
                this.inventory.rooms = [];
               //console.log('property_id', property_id);
                this.info_page.buttons.page_2 = false;
                this.info_page.buttons.page_1 = false;
                this.calendar_info.selected = [];





                roomService.getRooms('true', property_id).then(response => {
                    response.data.data.rooms.forEach(async tnmt => {
                       //console.log('tnmt', tnmt);

                        this.inventory.rooms.push({
                            _id:tnmt._id,
                            name: tnmt.name,
                            value: '',
                            status: 2,
                            cutOff: '',
                            edit_status: false
                        });
                    });

                   this.closeLoading();
                });


                /*let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month ];*/
                this.calendarDashboard(date_);
            },
            addRangeCalendar(){
                let array_dates =  this.inventory.date_range;

                let date_selecteds = this.calendar_info.selected;
                let range_init = array_dates[0];

                let range_start = moment(array_dates[0]);
                let range_end = moment(array_dates[1]);

                let weeekdays = this.inventory.week;


                while (range_start<=range_end){

                     let status_day = weeekdays[range_start.day()];

                     if(status_day){
                         let idx = date_selecteds.indexOf(range_start.format('YYYY-MM-DD'));

                         if(idx <0){

                             this.calendar_info.selected.push(range_start.format('YYYY-MM-DD'))
                         }
                     }
                    range_start.add(1, 'days');
                }
                //console.log('date', this.calendar_info.selected)
                this.checkButtonAvailable('page_1');
                let date_end_now = moment(this.calendar_info.monthsHead[2], 'MM-YYYY').startOf('month');
                let date_start_new = moment(range_init)
                if(date_start_new>date_end_now){
                    this.calendarDashboard(date_start_new)
                    this.checkDateNow()
                }
            },
            addMonth(month_s){
                let start_of= moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.calendar_info.selected;
                let array_result = [];

                while(start_of<daysMonth){


                    let aux = start_of.format('YYYY-MM-DD')
                    let date_now = moment();
                    if(!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')){

                        //console.log('es un dia disponible', aux)
                        let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                        if(idx <0){
                            array_result.push(start_of.format('YYYY-MM-DD'))
                        }
                    }

                    start_of.add(1, 'days')
                }
                //console.log('arr_r', array_result.length)

                if(array_result.length===0){
                    this.deleteMonth(month_s)
                }else{
                    this.calendar_info.selected = date_selecteds.concat(array_result)
                }

                this.checkButtonAvailable('page_1');
            },
            deleteMonth(month_s){
                let start_of= moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.calendar_info.selected;

                while(start_of<daysMonth){

                    let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                    if(idx>=0){
                        date_selecteds.splice(idx, 1)
                    }
                    start_of.add(1, 'days')
                }
            },
            resetCalendar(){
                this.calendar_info.selected = [];
                this.checkButtonAvailable('page_1');
                this.inventory.week =[1,1,1,1,1,1,1];
                this.inventory.date_range= ['', ''];
            },
            calendarDashboard(date_){
                let originalDate = moment(date_)
                let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month ];
                this.calendar_info.yearHead = originalDate.add(1, 'M').format('YYYY');

            },
            dayCalendar(date){
                let aux = '';
                if(date!==null){
                    aux = moment.utc(date, 'YYYY-MM-DD').format('D')
                    //console.log('date', aux)
                    //aux = moment.utc(date, 'YYYY-MM-DD').format('D')
                }

                return aux
            },
            addCalendar(date){
                let dates_Array = this.calendar_info.selected;
                if(date!==null){
                    //console.log('agregando o quitando fecha')


                    let aux = date
                    let date_now = moment();
                    if(!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')){

                        //console.log('es un dia disponible')
                        let idx = dates_Array.indexOf(date);
                        if(idx>=0){
                            dates_Array.splice(idx, 1)

                        }else{
                            dates_Array.push(date);
                        }
                    }
                }

                this.checkButtonAvailable('page_1');

                //console.log('dates_Array', dates_Array)
            },

            checkButtonAvailable(type_){
                switch (type_) {

                    case 'page_1':
                        if(this.calendar_info.selected.length){
                            this.info_page.buttons.page_1 = true;
                        }else{
                            this.info_page.buttons.page_1 = false;
                        }
                        break;
                    /*case 'page_2':
                        if(this.contracts.modified.length) {
                            this.info_page.buttons.page_2 = true;
                        }else{
                            this.info_page.buttons.page_2 = false;
                        }

                        break;*/
                }
            },




            nameMonth(date){
                return moment(date, 'MM-YYYY').format('MMMM')
            },


            checkDayBlock(date){
                let aux = '';
                if(date!==null){
                    aux = moment.utc(date, 'YYYY-MM-DD')
                    let date_now = moment.utc();
                    if(date_now>aux){
                       //console.log('bloquear date', aux)

                    }
                    //aux = moment.utc(date, 'YYYY-MM-DD').format('D')
                }

                //return aux
            },

            checkDateNow(){

                //let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY').subtract(1, 'M').format('YYYY-MM-DD')
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                let date_now = moment();
                //console.log(month_now, date_dashboard, month_now<date_dashboard)
                this.info_page.btn_back_status = date.isAfter(date_now, 'month')
            },


            updDate(action){
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                if(action==='plus'){
                    date.add(1, 'M')
                    this.calendarDashboard(date)

                }else if(action==='minus'){
                    date.subtract(1, 'M')
                    this.calendarDashboard(date)
                }
                this.checkDateNow();

            },


            checkDay(date){
                let dates_Array = this.calendar_info.selected;
                let response = '';
                let resp_status = '';

                if(date!==null){
                    response = dates_Array.indexOf(date);
                    if(response>=0){
                        resp_status = 'select-day';
                    }

                    let aux = date
                    let date_now = moment();
                    if(moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')){


                        resp_status = 'block-day';
                        //console.log('bloquear date', moment(aux).isSameOrAfter(date_now.format('YYYY-MM-DD'), 'day'))

                    }
                }
                return resp_status;
            },

            checkClass(idx){
               let result_class ='';
               if(idx===0){
                   result_class += 'w-80';
               }
                else if(idx===2){
                    result_class += 'ml-20 w-80';
                }
                return result_class
            },

            checkMonth(month_s){
                let start_of= moment.utc(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment.utc(month_s, 'MM-YYYY').endOf('month');
                let array_result = [];
                let array_model = [null, null, null, null,null, null, null];



                //console.log('start_of', moment().startOf('month').day())

                while(start_of<daysMonth){
                    //console.log('month_s', start_of.day())
                    array_model[start_of.day()] = start_of.format('YYYY-MM-DD');
                    if(start_of.day() === 6){

                        array_result.push(array_model);
                        array_model = [null, null, null, null, null, null, null];
                    }

                    start_of.add(1, 'days')
                }

                array_result.push(array_model);
                //console.log('array_result', array_result)
                return array_result;

            },
            checkStatus(status){
                //console.log(status)

                let states_ = this.info_page.states[status];

                //states_.find(item => item.value === status)



                return states_.name

            },
            orderDate(){
                if(this.info_page.buttons.page_1){
                    let calendar_array = this.calendar_info.selected;
                    //console.log('orderDate', )
                    calendar_array = calendar_array.sort()

                    this.calendar_info.min = moment(calendar_array[0]).format('DD MMM YYYY');
                    this.calendar_info.max = moment(calendar_array[calendar_array.length-1]).format('DD MMM YYYY');

                    this.info_page.index_page=2;
                }
            },
            async goResume(){
                if(this.info_page.buttons.page_2){
                    let dates_selected =   this.calendar_info.selected;
                    let data_form = [];

                    let start_date = this.calendar_info.min;
                    let end_date = this.calendar_info.max;
                    await this.inventory.rooms.forEach(async item => {

                        let data_cld = [];
                        let data_inv = [];
                        let data_cof = [];
                        /*let closedDates = await rateService.getClosedDates(item._id, {
                            from: start_date,
                            to: end_date,
                        });

                        closedDates = await closedDates.data.data.closed_dates;*/


                        //console.log('responseCD', closedDates);
                        dates_selected.forEach(async elem => {

                            //let responseCD = closedDates.find(item => moment.utc(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === elem)
                            let status_response = 0;

                            if(item.status  < 2){
                                status_response = item.status
                                data_cld.push({
                                    date: elem,
                                    value: status_response,
                                });
                            }
                            if(item.value!==''){
                                data_inv.push({
                                    date: elem,
                                    value: parseInt(item.value),
                                });
                            }
                            if(item.cutOff!==''){
                                data_cof.push({
                                    date: elem,
                                    value: parseInt(item.cutOff),
                                });
                            }
                        })
                        data_form.push({
                            room_id: item._id,
                            name: item.name,
                            info_inventario: data_inv,
                            info_closedDate: data_cld,
                            info_cutOff: data_cof
                        })
                        //await console.log('data_form', data_form);

                        this.inventory.formData = data_form;
                    });
                    this.info_page.index_page=3;
                }
            },
            sendForm(){
                this.initLoading('Guardando');
                this.saveChanges();

            },


            async saveChanges(){

                let dates_array = this.inventory.formData;
               //console.log('orderDate', dates_array)


                if(this.inventory.formData.length>0){
                    let item = this.inventory.formData[0]
                    let data_send = {
                        closed_dates: item.info_closedDate,
                        cutoff: item.info_cutOff,
                        inventory: item.info_inventario
                    }
                    await rateService.saveModified(item.room_id, data_send);
                   //console.log('paso 4', data_send );
                    this.inventory.formData.splice(0, 1)
                    this.saveChanges();
                }else{
                    this.closeLoading();
                    this.showSwal(this.$t('rate.dashboard.msg'));
                }
                /*await this.inventory.formData.forEach(item=>{


                })*/
            },
            cancelAction(){
                this.$router.push({
                    name: 'Rates'
                });
            },
            showSwal (msg) {

                swal({
                    title: this.$t('rate.dashboard.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {


                    this.$router.push({
                        name: 'RatesDates',
                        params: {
                            date: moment(this.calendar_info.min, 'DD MMM YYYY').format('YYYY-MM-DD'),
                        }
                    })


                    /*this.calendar_info.selected = [];
                    this.calendar_info.min = '';
                    this.calendar_info.max ='';
                    this.inventory = {
                        _id: '',
                            date_range: ['', ''],
                            end_date: '',
                            week:[1,1,1,1,1,1,1],
                            rooms:[

                        ],
                            formData: []

                    };
                    this.info_page.index_page= 1;*/

                });
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },
        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">

    .box-inventory{
        &.page-inventory{
            .form-updates{
                .group-upd{
                    margin-bottom: 20px;
                    display: inline-block;
                    label{
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                    span{
                        font-weight: 400;
                        margin-bottom: 10px;
                        display: inline-block;
                        color: #666;
                        font-size: 14px;
                    }
                    .select-default{
                        display: inline-block;
                        width: 100%;
                        float: left;
                        margin-left: 5px;
                        .el-input{
                            input{
                                padding-left: 10px;
                                padding-right: 30px;
                            }
                        }
                    }

                    .input-default{
                        display: inline-block;
                        width: 100%;
                        margin-right: 5px;
                        float: left;
                    }

                }
            }

        }
        border: 0;
        .is_title{
            font-size: 16px;
            margin: 0  0 15px;
        }
        .subtitle{
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button{
            //padding-left: 10px;
            .add-item{
                width: 100% ;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;

                &.inactive{
                    opacity: .2;
                    cursor: default;
                }
            }
        }
        .form-updates{
            .head{

                label{
                    font-size: 14px;
                    margin-bottom: 30px;
                    color: #666;
                    &.subtitle{
                        margin-bottom: 0;
                        font-size: 16px;
                        color: #000;
                    }
                }
            }
            .group-upd{
                margin-bottom: 20px;
                display: inline-block;
                label{
                   font-weight: 600;
                    margin-bottom: 0;
                }
                span{
                    font-weight: 400;
                    margin-bottom: 10px;
                    display: inline-block;
                    color: #666;
                    font-size: 14px;
                }
                .select-default{
                    display: inline-block;
                    width: 100%;
                    float: left;
                    margin-left: 5px;
                    .el-input{
                        input{
                            padding-left: 10px;
                            padding-right: 30px;
                        }
                    }
                }

                .input-default{
                    display: inline-block;
                    width: 100%;
                    margin-right: 5px;
                    float: left;
                }

            }
        }
        .form-dates{

            .box-dates{
                .form-check{
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .dates-manually{
                width: 100% ;
                .box-calendar{
                    width: 100%;
                }
                .box-legends{
                    width: 100%;
                    padding: 15px 0;
                    display: inline-block;
                    label{
                        width: 50%;
                        float: left;
                        display: inline-block;
                        margin-bottom: 0;
                        &.color-blue{
                            color: #3B4FBF;
                        }
                        &.right-t{
                            text-align: right;
                        }
                        .example-picker{
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            background: #3B4FBF;
                            margin-right: 7px;
                            border-radius: 15px;
                        }
                    }

                }
            }
        }



        .w-border{
            border:2px solid #bebebe;
            padding: 10px 25px;
            margin-bottom: 15px;
        }
        .form-preview{
            position: relative;
            .l-title{
                font-weight: 600;
            }
            .text-normal{
                font-size: 14px;
            }
            .brl{
                display: inline-block;
                width: 100%;
            }
            .a-right{
                position: absolute;
                right: 50px;
                top: 0;
                bottom: 0;
                left: auto;
                margin: auto;
            }
            .type-button{
                width: 45px;
                height: 45px;
                color: #ffffff;
                text-align: center;
                border-radius: 100px;
                cursor: pointer;
                i{

                    font-size: 25px;
                    line-height: 45px;
                    left: -3px;
                    position: relative;
                }

                &:hover{
                    color: #fff;
                }
            }
            .type-button{
                right: 120px;
            }
            .type-text{
                height: 45px;
                line-height: 45px;
                cursor: pointer;
            }
            .p-contain{
                width: calc(100% - 40%);
                position: relative;
                display: inline-block;
                border-bottom: 1px solid;
                margin-bottom: 5px;
                .s-left{
                    display: inline-block;
                    width: 50%;
                }
                .s-right{
                    text-align: right;
                    display: inline-block;
                    width: 50%;
                }
            }

        }
        .form-send{
            .s-icon{
                font-size: 20px;
                color: #bf9000;
            }
            label{
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .box-button{
                button{
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
            }

        }





        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label{
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            font-size: 16px;
            text-transform: unset;
            span{
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont{
                margin-left: 0;
            }

        }
        h6{
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }
        .box-calendar{
            .item-month{

                .calendar-title{
                    font-size: 17px;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    //width: 60%;
                    &.w-80{
                        width: 60%;
                    }
                    &.ml-20{
                        margin-left: 20%;
                    }
                }
                .sp-icon{
                    width: 20%;
                    text-align: center;
                    &.n-visible{
                        visibility: hidden;
                    }
                }
                display: inline-block;
                width: 30%;
                float: left;
                &:first-child{
                    margin-right: 5%;
                }
                &:last-child{
                    margin-left: 5%;
                }
                .line-week{
                    width: 100%;
                    display: inline-block;
                    &.lw-head{
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .item-7{
                        cursor: pointer;
                        display: inline-block;
                        width: calc(100% / 7);
                        text-align: center;
                        height: 25px;
                        line-height: 25px;
                        float: left;
                        .select-day{
                            background: #3B4FBF;
                            width: 25px;
                            display: inline-block;
                            border-radius: 100px;
                            color: #fff;
                        }
                        .block-day{
                            background: #E1D8E9;
                            width: 100%;
                            display: inline-block;
                            color: #000;
                            cursor: default;
                        }
                    }
                }
            }
        }

    }
    label.year-header{
        font-size: 22px;
        font-weight: 600;
    }
</style>
