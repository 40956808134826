import BackendService from './BackendService';

export default class RateService extends BackendService {

    constructor() {
        super();
    }
    getContracts() {
        let contract = [
            {
                name: 'Inventario',
                values: [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]

            },
            {
                name: 'Reservaciones',
                values: [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]

            },
            {
                name: 'Cut off',
                values: [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]

            }
        ]
        let status = [0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
        let data = {
            status: status,
            contract: contract
        };


        return data;



    }
    getRates(parameters){
        return this.httpCall('rates',{
            params: parameters,
        });

    }
    getRatesTarYRest(parameters){
        return this.httpCall('rates',{
            params: parameters,
        });

    }
    getRateConfigData(parameters){
        return this.httpCall('rate-config-data',{
            params: parameters,
        });

    }

    arrayRates(items){

        return this.httpCall('rates',{
            method: 'POST',
            data: items,
        });


    }

    createRate(item) {

        return this.httpCall('rate',{
            method: 'POST',
            data: {
                rate_config: item.rate_config,
                date: item.date,
                value: item.value,
                /*min_nights: 1,
                max_nights: 1*/
            },
        });

    }
    updateRate(item) {
        //console.log('user_info', user);

        return this.httpCall('rate/'+item._id,{
            method: 'PUT',
            data: {
                rate_config: item.rate_config,
                date: item.date,
                value: item.value,
                /*min_nights: 1,
                max_nights: 1*/
            },
        });

    }
    getInventory(room_id, parameters){
        return this.httpCall('room/'+room_id+'/inventory',{
            params: parameters,
        });

    }
    getDataModified(room_id, parameters){
        return this.httpCall('room/'+room_id+'/modifiers',{
            params: parameters,
        });

    }

    saveInventory(room_id, items){
        return this.httpCall('room/'+room_id+'/inventory',{
            method: 'POST',
            data: items,
        });

    }
    getClosedDates(room_id, parameters){
        return this.httpCall('room/'+room_id+'/closed-dates',{
            params: parameters,
        });

    }
    saveClosedRates(room_id, items){
        return this.httpCall('room/'+room_id+'/closed-dates',{
            method: 'POST',
            data: items,
        });

    }



    saveModified(room_id, items){
        return this.httpCall('room/'+room_id+'/modifiers',{
            method: 'POST',
            data: items,
        });

    }

    getCutOffs(room_id, parameters){
        return this.httpCall('room/'+room_id+'/cutoff',{
            params: parameters,
        });

    }
    saveCutOffs(room_id, items){
        return this.httpCall('room/'+room_id+'/cutoff',{
            method: 'POST',
            data: items,
        });

    }



}
